<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-help-toc-outer">
	<div class="d-flex mb-1" style="overflow:hidden">
		<v-text-field v-model="search" prepend-inner-icon="fa fa-search" label="Search" single-line hide-details outlined dense background-color="#fff" clearable clear-icon="fa fa-times-circle"></v-text-field>
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" small color="light-blue darken-3" icon @click="toggle_expand_all" style="margin-left:0px; margin-right:-8px; margin-top:1px;"><v-icon small>fas fa-up-down</v-icon></v-btn></template>Expand/collapse all help topics</v-tooltip>
	</div>
	<ul class="k-help-toc-item-children">
		<HelpTOCItem v-for="(child, key) in toc" :key="key" :top_level="true" :doc_key="key" :item="child" :current_doc="current_doc" :search_re="search_re" @toggle_expanded="child.expanded=!child.expanded" />
	</ul>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import HelpTOCItem from './HelpTOCItem'
import HelpMixin from './HelpMixin'

// PW 2/27/2024: I don't think we actually need to explicitly import the individual help components
// import './overview'
// import './framework_index'
// import './adoption_status'
// import './signing_in'
// import './explorer'
// import './treeview'
// import './document_card'
// import './item_card'
// import './expanded_card'
// import './more_info'
// import './view_assoc'
// import './pinning'
// import './tree_toolbar'
// import './tileview'
// import './search'
// import './keyboard_shortcuts'
// import './tableitemsview'
// import './tableassociationsview'
// import './kebab_menu'
// import './archives'
// import './archive_compare'
// import './export_options'
// import './reviewing'
// import './add_comments'
// import './show_all_comments'
// import './comment_groups'
// import './comment_attn'
// import './comment_mark_as_read'
// import './suggest_edits'
// import './editing'
// import './create_framework'
// import './edit_document'
// import './edit_item'
// import './supplemental_info'
// import './supplemental_branches'
// import './add_item'
// import './copy_item'
// import './batch_add'
// import './move_item'
// import './batch_update'
// import './create_assoc'
// import './delete_item'
// import './delete_framework'
// import './mirrors'
// import './admin'
// import './user_roles_admin'
// import './roles_system'
// import './roles_framework'
// import './framework_categories'
// import './access_reports'
// import './case'
// import './case_json'
// import './case_document'
// import './case_items'
// import './case_associations'
// import './identifiers'
// import './case_rest_api'
// import './case_network'
// import './framework_notification'
// import './framework_update'
// import './framework_update_archives'
// import './framework_update_table'
// import './framework_update_track'
// import './batch_add_import'
// import './batch_add_text'
// import './batch_add_troubleshoot'
// import './orphaned_items'
// import './public_review_comments'
// import './public_review_settings'




import './sort_children'

export default {
	mixins: [HelpMixin],
	components: { HelpTOCItem },
	props: {
		current_doc: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		all_expanded: false,
		search: '',
		toc: {},
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		search_re() {
			if (empty(this.search)) return false
			return new RegExp('(' + this.search + ')', 'gi')
		}
	},
	watch: {
		search() {
			let search_fn = (children) => {
				for (let doc_key in children) {
					let child = children[doc_key]
					child.search_match = (U.html_to_text(child.title).search(this.search_re) > -1)
					if (child.children) search_fn(child.children)
				}
			}
			search_fn(this.toc)
		}
	},
	created() {
	},
	mounted() {
		this.toc = {
			// 16 incomplete
			overview: 				{ search_match:false, title: 'Overview', expanded: false, children: {
				framework_index: 	{ search_match:false, title: this.framework_list_title },
				adoption_status: 	{ search_match:false, title: 'Adoption status' },
				signing_in: 		{ search_match:false, title: 'Signing in' },
				framework_notification: { search_match:false, title: 'Framework notification preferences' },
				framework_update: 	{ search_match:false, title: 'Framework update report', expanded: false, children:{
					framework_update_archives:    {search_match:false, title: 'Show All Archives'},
					framework_update_table:    {search_match:false, title: 'Archive Comparison - Table View'},
					framework_update_track:    {search_match:false, title: 'Archive Comparison - Track Changes View'},
				} },
				privacy_policy: { search_match:false, title: 'Privacy Policy' },
			}},
			explorer: 				{ search_match:false, title: this.browsing_interface_title, expanded: false, children: {
				treeview: 			{ search_match:false, title: 'Tree view', position: 'right', children: {
					document_card: 	{ search_match:false, title: 'Document card', position: 'left' },
					item_card: 		{ search_match:false, title: 'Item card', position: 'left' },
					expanded_card: 	{ search_match:false, title: 'Expanded cards' },
					more_info: 		{ search_match:false, title: 'Item metadata/Change history', position: 'right' },
					view_assoc: 	{ search_match:false, title: 'View associations' },
					view_rubrics: 	{ search_match:false, title: 'View Rubrics' },
					pinning: 		{ search_match:false, title: 'Pinning items' },
					tree_toolbar: 	{ search_match:false, title: 'Tree view toolbar', position: 'right' },
				}},
				tileview: 			{ search_match:false, title: 'Tile view', position: 'right' },
				search: 			{ search_match:false, title: 'Searching standards and other items', position: 'left' },
				keyboard_shortcuts: { search_match:false, title: 'Keyboard shortcuts' },
				tableitemsview: 	{ search_match:false, title: 'Items table' },
				tableassociationsview: { search_match:false, title: 'Associations table' },
				kebab_menu: 		{ search_match:false, title: 'Kebab menu', position: 'left' },
				archives: 			{ search_match:false, title: 'Framework archives', children: {
					archive_compare: { search_match:false, title: 'Comparing archives' }, 
				}},
				export_options: 	{ search_match:false, title: 'Printing/exporting options', children:{
					print_pdf:    {search_match:false, title: 'Print (or save as PDF)'},
					export_spreadsheet: {search_match:false, title: 'Export to a Spreadsheet'},
					export_case_json: {search_match:false, title: 'Export CASE JSON'},
					export_canvas_spreadsheet: {search_match:false, title: 'Export to Canvas Spreadsheet Format'},
					copy_case_api: {search_match:false, title: 'Copy CASE package API link'},
					satchel_text_format: {search_match:false, title: 'Satchel text format'},
				}},
			}},
			reviewing: 				{ search_match:false, title: 'Reviewing frameworks', expanded: false, children: {
				add_comments: 		{ search_match:false, title: 'Commenting on items', position: 'left' },
				show_all_comments: 	{ search_match:false, title: 'Show all comments' },
				comment_groups: 	{ search_match:false, title: 'Comment groups' },
				comment_attn: 		{ search_match:false, title: 'Tagging comment group members' },
				comment_mark_as_read: { search_match:false, title: 'Marking comments as read' },
				suggest_edits: 		{ search_match:false, title: 'Suggesting edits', position: 'left' },
				public_review_settings: { search_match:false, title: 'Public Review Settings', position: 'left' },
				public_review_comments: { search_match:false, title: 'Public Review Comments', position: 'left' },
			}},
			editing: 				{ search_match:false, title: 'Authoring/editing standards', expanded: false, children: {
				create_framework: 	{ search_match:false, title: 'Creating frameworks' },
				edit_document: 		{ search_match:false, title: 'Editing document properties', position: 'left' },
				edit_item: 			{ search_match:false, title: 'Edit Items', position: 'left' },
				supplemental_info: 	{ search_match:false, title: 'Supplemental item information' },
				supplemental_branches: { search_match:false, title: 'Supplemental branches' },
				orphaned_items: { search_match:false, title: 'Orphaned Items' },
				add_item: 			{ search_match:false, title: 'Adding new items' },
				create_sandbox: 			{ search_match:false, title: 'Create a Sandbox Copy' },
				copy_item: 			{ search_match:false, title: 'Copy items' },
				batch_add_from_text: { search_match:false, title: 'Batch import items from text input', position:'left' },
				batch_add_from_csv: { search_match:false, title: 'Batch import items from CSV file', position:'left' },
				move_item: 			{ search_match:false, title: 'Move items' },
				sort_children: 		{ search_match:false, title: 'Sort items' },
				batch_update: 		{ search_match:false, title: 'Batch update items' },
				create_assoc: 		{ search_match:false, title: 'Create associations' },
				batch_import_associations: 	{ search_match:false, title: 'Batch import associations' },
				create_edit_rubrics:	{ search_match:false, title: 'Create & Edit Rubrics' },
				delete_item: 		{ search_match:false, title: 'Delete items' },
				delete_framework: 	{ search_match:false, title: 'Delete frameworks' },
				mirrors: 			{ search_match:false, title: 'Mirror frameworks' },
				
			}},
			admin: 					{ search_match:false, title: 'Admin functions', expanded: false, children: {
				roles_system: 		{ search_match:false, title: 'System user roles', position:'right' },
				roles_framework: 	{ search_match:false, title: 'Framework user roles', position:'right' },
				manage_users: 	{ search_match:false, title: 'Manage Users', position:'right' },
				// user_roles_admin: 	{ search_match:false, title: 'Manage user roles' },
				framework_categories: { search_match:false, title: 'Manage framework categories', position:'right' },
				access_reports: 	{ search_match:false, title: 'Framework access report', position:'right' },
			}},
			case: 					{ search_match:false, title: 'What is CASE? / The CASE Ecosystem', expanded: false, children: {
				case_json:	 		{ search_match:false, title: 'CASE JSON' , expanded: false, children: {
					case_document:	{ search_match:false, title: 'CASE Document' },
					case_items:		{ search_match:false, title: 'CASE Items' },
					case_associations: { search_match:false, title: 'CASE Associations' },
					identifiers: 	{ search_match:false, title: 'CASE Item identifiers (GUIDs)' },
				}},
				case_rest_api: 		{ search_match:false, title: 'CASE REST APIs' },
				case_network: 		{ search_match:false, title: 'CASE Network 2' },
				satchel_plans: 		{ search_match:false, title: 'Satchel Publishing Options' },
			}},
		}
	},
	methods: {
		toggle_expand_all(val) {
			if (typeof(val) == 'boolean') this.all_expanded = val
			else {
				// clear search when button is clicked
				this.search = ''
				this.all_expanded = !this.all_expanded
			}

			for (let child in this.toc) {
				this.toc[child].expanded = this.all_expanded
			}
		},

		find_toc_item(doc) {
			let find = (parent) => {
				for (let key in parent) {
					if (key == doc) return parent[key]
					if (parent[key].children) {
						let temp = find(parent[key].children)
						if (temp) return temp
					}
				}
				return null
			}
			return find(this.toc)
		},
	}
}
</script>

<style lang="scss">
</style>