// Copyright 2022, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'
// in includer
// import PostMessageMixin from './PostMessageMixin'

export default {
	// in includer
	// mixins: [PostMessageMixin],

	data() { return {
		// pm_verbose: 'show_untrusted',
		pm_verbose: '',
		last_loaded_framework_identifier: null,
		last_opened_pm_data: {},
		pm_initialize_try_count: 0,
	}},
	computed: {
		...mapState(['embedded_mode', 'cureum_standards_tabbed']),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		// this is the initialize fn for window B that is embedded in/opened from window A
		// here, we establish the eventListener for postMessages, then wait for a message from window A establishing the connection.
		pm_initialize() {
			// if we don't yet have window.satchel_site_config (which may hold post_message_trusted_origins) for some reason, call again in 100 ms
			if (empty(window.satchel_site_config)) {
				// but give up after 50 tries
				if (this.pm_initialize_try_count >= 50) return
				++this.pm_initialize_try_count
				setTimeout(x=>{this.pm_initialize}, 100)
				console.log('retrying pm_initialize in 100ms')
				return
			}

			// host application origins that are allowed to embed this instance of satchel; as of 4/20/2024, brought in via site_config
			let trusted_origins = this.$store.state.site_config.post_message_trusted_origins

			// trust ourself! (satchel-in-satchel -- used, e.g., in crosswalk tool)
			trusted_origins.push(window.location.origin)
			console.log(trusted_origins)

			// set up eventListener for postMessages
			window.addEventListener('message', (event) => {
				// event.source is the window that sent the message (the window that included the iframe)
				// event.origin is the origin that sent it; make sure we trust it
				// event.data is what was sent

				// Do we trust the sender of this message?
				if (!trusted_origins.includes(event.origin)) {
					if (this.pm_verbose == 'show_untrusted') {
						if (event.origin == window.location.origin) console.warn('   PMX SATCHEL !== message in satchel from self')
						else console.warn('   PMX SATCHEL !== message in satchel from untrusted origin: ' + event.origin, event.data)
					}
					return
				}

				// messages should all have the form {msg: 'xxx', data: ...}
				if (typeof(event.data) != 'object' || empty(event.data.msg)) {
					if (event.data?.type?.indexOf('webpack') !== 0 && event.data?.indexOf('webpack') !== 0) {
						console.warn('   !-- PMX SATCHEL: bad message received', event.data)
					}
					return
				}
				let msg = event.data.msg
				let data = event.data.data

				// message from hosting window to establish connection
				if (msg == 'establish_connection') {
					// if we already established the connection, return
					if (this.embedded_source) {
						if (this.pm_verbose) console.warn('   PMX SATCHEL <-- establish_connection received after connection already established')
						return
					}

					if (this.pm_verbose) console.warn('   PMX SATCHEL <-- EMBEDDED_SOURCE ESTABLISHED FROM HOST')

					// it looks like we get errors if embedded_source is reactive -- but that's OK; it doesn't need to be
					this.embedded_source = event.source
					this.embedded_source_origin = event.origin

					// send a confirmation message that the message has been received
					this.pm_send('received_establish_connection')

					// if we're in `embedded_mode` mode (as opposed to `cureum_standards_tabbed` mode)
					if (this.embedded_mode) {
						// after a tick to see if the caller is telling us to load a particular framework...
						this.bootstrap_timeout = setTimeout(()=>{
							// call pm_load_framework to show the chooser or load the previously-loaded framework
							this.pm_load_framework({})
						}, 100)		// this delay was 500 previously...
					}
				
				//////////////////////////////////
				// HERE ARE THE COMMANDS THAT CAN BE SENT FROM THE HOSTING WINDOW

				// load_framework or jump_to_item (these are command aliases; they do the same thing)
				} else if (msg == 'load_framework' || msg == 'jump_to_item') {
					this.pm_load_framework(data)

				} else if (msg == 'clear_selected_items') {
					// clear selected_items from the last-loaded framework
					this.clear_selected_items()

				} else if (msg == 'select_item') {
					// select/deselect the provided item
					this.select_item(data)

				// set the chooser mode, so that the chooser icons will be shown
				} else if (msg == 'chooser') {
					if (this.pm_verbose) console.warn('   PMX SATCHEL <-- cmd received: chooser_mode')
					this.$store.commit('set', ['embedded_mode_chooser', data.chooser_mode])
					setTimeout(x=>{
						// if the framework viewer is showing (it should be)
						if (this.case_tree_component) {
							this.set_embedded_mode_chooser_fn()
						}
					}, 100)

				// enter search_terms in the search field of the currently-opened framework
				} else if (msg == 'search') {
					if (this.pm_verbose) console.warn('   PMX SATCHEL <-- cmd received: search')
					// a framework must be open and showing here; so the calling command should do the search within a promise for loading the framework
					if (this.case_tree_component) {
						let limit_tree_key
						if (data.limit_to) {
							let framework_record = this.case_tree_component.framework_record
							limit_tree_key = framework_record?.cfo?.cfitems[data.limit_to]?.tree_nodes[0]?.tree_key
						}
						if (limit_tree_key) {
							this.case_tree_component.search_item_for_limit = limit_tree_key
							this.case_tree_component.search_limit_to_item = true
						} else {
							this.case_tree_component.search_item_for_limit = ''
							this.case_tree_component.search_limit_to_item = false
						}

						this.case_tree_component.reveal_search_bar()
						setTimeout(()=>{
							this.case_tree_component.search_terms = data.search_terms
							// do an advanced search unless the advanced_search param is explicitly set as false
							this.case_tree_component.execute_search_start(true, (data.advanced_search !== false))
						}, 500)
					}

				} else if (msg == 'open_in_new_window') {
					window.open(document.location)

				} else if (msg == 'get_current_location') {
					// return the current document.href; Cureum uses this to open that href in a new window when Satchel is embedded
					this.pm_send('current_location', {
						href: window.document.location.href
					})

					// more messages here...
				} else {
					console.warn('   PMX SATCHEL !== received unprocessed message: ', msg, data)
				}
			});
		},

		pm_load_framework(data) {
			// possible params in data: 
			// framework_identifier: guid 		-- the framework to open
			// item_identifier: guid			-- this item will be open and its tile will be shown at the start, and it will serve as the "head" for selected items
			// selected_items: array of guids	-- if specified, these items will be shown with checkboxes, while other items will have open circles
			// selected_items_ancestor: guid    -- if specified, only selected-item nodes below this ancestor will be selected (and shown if limit_to_selected_items is on)
			// limit_to_selected_items: mixed	-- this is awkwardly-named, but important for different contexts. possible values:
			// 								        -- 'only': *only* show the selected items (and their ancestors) -- when the interface is first shown
			// 								        -- 'children': also show the selected items' children
			// 								        -- false: don't limit to selected items at all
			// no_framework_reset: boolean      -- default false; if true, we won't reset the framework's open nodes if the parameters of this launch are roughly the same as the parameters from the previous launch of the same framework (see logic below)
			// sourceItemIdentifier_translation -- by default, if a supplied item_identifier or selected_item can't be found, we'll see if we can find an item with that identifier as the sourceItemIdentifier. Usually this is what we want, but if sourceItemIdentifier_translation === false, we *won't* try to do this translation

			if (this.pm_verbose) console.warn('   PMX SATCHEL <-- cmd received: load_framework', data)
			// cancel the bootstrap_timeout, which might otherwise show the chooser or the last-viewed framework
			clearTimeout(this.bootstrap_timeout)

			// TODO: if user specifies item_identifier and not framework_identifier, first look up the framework_identifier from the item

			// use setInterval to make sure/wait until the framework_list_component is available (after the framework list has been loaded)...
			// console.warn(`set pm_wait_for_framework_list_component_interval (old: ${this.pm_wait_for_framework_list_component_interval} / this._uid: ${this._uid}): selected_items.length = ${data.selected_items?.length}`)
			// 2024-12-20: found that it's important to clearInterval here; otherwise sometimes we end up with two intervals going at the same time
			clearInterval(this.pm_wait_for_framework_list_component_interval)
			this.pm_wait_for_framework_list_component_interval = setInterval(()=>{
				// if not loaded yet, return and try again on the next interval
				if (!vapp.framework_list_component) return
				clearInterval(this.pm_wait_for_framework_list_component_interval)

				// IF WE GET TO HERE, THE FRAMEWORK IS LOADED IN case_tree_component AND READY TO BE SHOWN

				// if a framework_identifier was not specified, look for one in lst
				if (!data.framework_identifier) data.framework_identifier = this.$store.state.lst.embedded_framework_identifier

				// if we still don't have a framework identifier, or if data.framework_identifier is 'switcher', open the framework switcher
				if (!data.framework_identifier || data.framework_identifier == 'switcher') {
					// note that in this case we don't send back the framework_loaded message
					vapp.show_framework_switcher()
					return
				} else {
					// else hide the switcher, in case it was showing
					vapp.hide_framework_switcher()
				}

				// make sure we have access to this framework on this satchel server
				let framework_record = this.$store.state.framework_records.find(x=>x.lsdoc_identifier == data.framework_identifier)
				if (!framework_record) {
					// show an alert, then show the framework switcher
					this.$alert('The specified framework could not be loaded.').then(x=>vapp.show_framework_switcher())

					// send a message to the embedder that the framework couldn't be loaded
					vapp.pm_send('load_framework_failed', {framework_identifier: data.framework_identifier})
					
					return
				}

				// save the opened framework identifier in last_loaded_framework_identifier, so we can clear selected items if necessary
				this.last_loaded_framework_identifier = data.framework_identifier

				// store the opened framework identifier in state.lst, so we'll know to re-open this one later (also needed for FrameworkSwitcher)
				this.$store.commit('lst_set', ['embedded_framework_identifier', data.framework_identifier])

				// set sourceItemIdentifier_translation; note that we set to true by default (so if it's null, sourceItemIdentifier_translation will be set to true)
				this.$store.commit('set', [framework_record, 'sourceItemIdentifier_translation', (data.sourceItemIdentifier_translation !== false)])

				// if we've been told not to reset the framework unless necessary, determine if this data is different than the data sent the last time this framework was shown
				// default state: reset framework if we received an item_identifier or selected_items
				let reset_framework = (data.item_identifier || data.selected_items)
				let lopd = this.last_opened_pm_data[data.framework_identifier]
				// if default is to reset, and we have a last_opened_pm_data record, and no_framework_reset is true...
				if (reset_framework && lopd && data.no_framework_reset === true) {
					// set reset_framework to false, then change it back to true if necessary
					reset_framework = false 

					// the only situation that would require us to reset despite no_framework_reset being true would be if selected_items is different and limit_to_selected_items is or was truthy -- in this case, we might not have been showing the newly-selected items (and if no items are selected now, we might be hiding items the user wants to see
					// note: if we get to the third of the || clauses here, we both did and now have selected_items
					if ((lopd.selected_items && !data.selected_items) || (!lopd.selected_items && data.selected_items) || (lopd.selected_items && data.selected_items && lopd.selected_items.length != data.selected_items.length)) {
						// if we had selected_items and now don't, or if we didn't have selected items and now do, reset anyway
						reset_framework = true

					} else if (lopd.limit_to_selected_items || data.limit_to_selected_items) {
						// else if we were or are limiting to selected items, make sure the list of selected items is identical
						for (let si of lopd.selected_items) {
							if (!data.selected_items.includes(si)) {
								reset_framework = true
								break
							}
						}
					}
				}

				// console.warn(`pm_wait_for_framework_list_component_interval (${this.pm_wait_for_framework_list_component_interval}): selected_items.length = ${data.selected_items?.length}`)

				// if according to the above we *are* supposed to reset the framework...
				if (reset_framework) {
					// clear any previously-opened or selected nodes
					this.$store.commit('set', [framework_record, 'open_nodes', {}])
					this.$store.commit('set', [framework_record, 'active_node', ''])
					this.$store.commit('set', [framework_record, 'last_clicked_node', ''])
					this.$store.commit('set', [framework_record, 'selected_items', null])
					this.$store.commit('set', [framework_record, 'limit_to_selected_items', false])
					this.$store.commit('set', [framework_record, 'selected_items_ancestor', ''])

					// then if selected_items is set...
					if (data.selected_items) {
						this.$store.commit('set', [framework_record, 'selected_items', data.selected_items])
						this.$store.commit('set', [framework_record, 'limit_to_selected_items', (data.limit_to_selected_items || false)])
						this.$store.commit('set', [framework_record, 'selected_items_ancestor', (data.selected_items_ancestor || '')])
					}

					this.$store.commit('set', [framework_record, 'open_selected_nodes_when_calculated', true])

				} else {
					// even if we're not fully resetting the framework, we need to set selected_items to null if we didn't get selected items this time
					if (!data.selected_items || data.selected_items.length == 0) {
						this.$store.commit('set', [framework_record, 'selected_items', null])
						this.$store.commit('set', [framework_record, 'limit_to_selected_items', false])
					}
				}
				this.last_opened_pm_data[data.framework_identifier] = data

				// if the framework is already loaded, compensate for duplicated items here (if loading a new framework, this will be done after the framework is loaded)
				if (framework_record.framework_json_loaded) {
					U.process_framework_record_sourceItemIdentifiers(framework_record)
					if (data.item_identifier) data.item_identifier = U.get_originals_from_sourceItemIdentifiers(data.item_identifier, framework_record)
				}

				// if the specified framework isn't currently showing...
				if (vapp.framework_list_component.case_tree_lsdoc_identifier != data.framework_identifier) {
					// ... then if a different framework was loaded previously, we have to first remove the CASEFrameworkViewer component, then re-open it a tick later, to force the new framework to load
					if (vapp.case_tree_component?.hide_tree) {
						vapp.case_tree_component.hide_tree()
						this.$nextTick(()=>vapp.framework_list_component.view_framework(data.framework_identifier, data.item_identifier))
					} else {
						// otherwise we can just open the viewer component; it will do the pm_send('framework_loaded') when it finishes initilizing
						vapp.framework_list_component.view_framework(data.framework_identifier, data.item_identifier)
					}
				} else {
					// else the specified framework is already showing.
					// send the framework_loaded message back to the host, so it can proceed with its promise
					vapp.pm_send('framework_loaded', {framework_identifier: data.framework_identifier, item_identifier: data.item_identifier, source: 2})

					// and if item_identifier is specified and that item isn't already, call show_item
					if (data.item_identifier) {
						// if reset_framework is *false*, send 'leave_open_nodes' so show_item won't reset open_nodes
						vapp.case_tree_component.show_item(data.item_identifier, (reset_framework ? null : 'leave_open_nodes'))
					}
				}
			}, 50)
		},

		pm_send(msg, data) {
			// if we're not embedded, return
			if (!this.embedded_source) return

			if (this.pm_verbose) console.warn('   PMX SATCHEL --> pm_send: ' + msg)

			try {
				// queue a message to be sent; second param specifies what the origin of the target window must be for the event to be dispatched
				this.embedded_source.postMessage({msg: msg, data: data}, this.embedded_source_origin)
			} catch(e) {
				console.warn('   PMX SATCHEL !== pm_send error caught: ', e)
			}
		},

		set_embedded_mode_chooser_fn() {
			// set the embedded_mode chooser_fn if necessary. 
			// This should be called immediately after loading or reloading a framework
			if (this.$store.state.embedded_mode_chooser) {
				this.case_tree_component.show_chooser_fn = (component, item, evt) => {
					// incoming item is the cfo node. send back the framework_identifier and the cfitem; convert to "proper" CASE JSON
					// PW 12/25/2024: add childOf with the identifier of the parent item, and sequenceNumber of the child in the parent; cureum (e.g.) wants this
					let childOf, sequenceNumber
					if (item.parent_node?.cfitem) {	// should always be true
						childOf = item.parent_node.cfitem.identifier
						sequenceNumber = item.parent_node.children.findIndex(x=>x.cfitem.identifier==item.cfitem.identifier)
					}
					vapp.pm_send('item_chosen', {
						framework_identifier: this.$store.state.lst.embedded_framework_identifier,
						cfitem: new CFItem(item.cfitem).to_json(),
						childOf: childOf,
						sequenceNumber: sequenceNumber
					})
				}
			} else {
				// cancel chooser mode
				this.case_tree_component.show_chooser_fn = false
			}
		},

		clear_selected_items() {
			let framework_record = this.$store.state.framework_records.find(x=>x.lsdoc_identifier == this.last_loaded_framework_identifier)
			this.$store.commit('set', [framework_record, 'selected_items', null])
			this.$store.commit('set', [framework_record, 'limit_to_selected_items', false])
			this.$store.commit('set', [framework_record, 'selected_items_ancestor', ''])

			// clear selected_items from last_opened_pm_data so that if it gets called again the selected_items will be re-shown
			if (this.last_opened_pm_data[this.last_loaded_framework_identifier]) {
				this.last_opened_pm_data[this.last_loaded_framework_identifier].selected_items = null
			}
		},

		select_item(data) {
			// data should include val (true/false) and item_identifier
			// this is generally used when we're in chooser mode and allowing for multiple selections; call this to select/deselect the item the user just chose.
			// we assume the item's framework is already open and set to chooser mode; if not, nothing should happen
			let framework_record = this.case_tree_component?.framework_record
			if (!framework_record) {
				console.warn('   PMX SATCHEL !== select_item failed', data)
				return
			}

			if (data.val == true) {
				if (!framework_record.selected_items) {
					this.$store.commit('set', [framework_record, 'selected_items', [data.item_identifier]])
				} else {
					this.$store.commit('set', [framework_record.selected_items, 'PUSH', data.item_identifier])
				}
			} else {
				if (framework_record.selected_items) {
					let index = framework_record.selected_items.findIndex(x=>x==data.item_identifier)
					if (index > -1) {
						this.$store.commit('set', [framework_record.selected_items, 'SPLICE', index])
						if (framework_record.selected_items.length == 0) {
							this.$store.commit('set', [framework_record, 'selected_items', null])
						}
					}
				}
			}
		},
	}
}